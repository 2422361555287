import React from "react";

export default ({recheck}) => {
    return (<p>
        There are currently no ongoing raffles. <br/>
        Check in on our discord <a href="https://eniigmakeyboards.com/discord">eniigmakeyboards.com/discord</a><br/>
        for the latest info on our raffles.<br/>
        <br/>
        <button onClick={recheck}>Check again for open raffles</button>
    </p>);
};