import React from "react";

const loginUrl = `https://discord.com/api/oauth2/authorize?client_id=${process.env.DISCORD_CLIENT_ID}&redirect_uri=${process.env.DISCORD_RETURN_URL}&response_type=code&scope=identify%20email%20guilds`;

const goToLogin = () => {
    window.location.assign(loginUrl);
}

export default () => {
    return (<div>
        <button onClick={goToLogin}>Sign in with Discord</button>
    </div>);
};