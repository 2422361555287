import React, { useState, useEffect } from "react";
// import white from "url:./assets/white.webp";
import gray from "url:./assets/gray.jpg";
import purple from "url:./assets/purple.jpg";

export default ({ raffle, getRaffles, loginInfo }) => {
    const [raffleSpinner, setRaffleSpinner] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [visibleImage, setVisibleImage] = useState(0);
    const [entry, setEntry] = useState({
        name: raffle.name,
        options: { name: raffle.options.length == 1 ? raffle.options[0].name : null },
        extras: raffle.extras.map(x => { return { name: x.name, items: 0 }; })
    }); 

    const slides = [
        {src: gray, key: 0, name: "Charcoal Grey", alt: "Charcoal Grey Vertigo"},
        {src: purple, key: 1, name: "Byzantine Purple", alt: "Byzantine Purple Vertigo"},
        // {src: white, key: 2, name: "Vertigo SE", alt: "E-White Special Edition Vertigo"},
    ];

    const showModal = async () => {
        if (!entry.options.name && raffle.options.length > 0) {
            setShowWarning(true);
            return;
        }
        setModalOpen(true);
    };

    useEffect(() => {
        if(entry.options.name) {
            let slide = slides.find(x => x.name === entry.options.name);
            setVisibleImage(slide?.key);
        };
        const interval = setInterval(() => {
            setVisibleImage((visibleImage + 1) % slides.length);
        }, 5000);
        return () => clearInterval(interval);
      }, [visibleImage, entry.options.name]);

    const enterRaffle = async () => {
        setRaffleSpinner(true);
        let result : any;
        try {
            result = await fetch("/api/entry", {
                body: JSON.stringify(entry),
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                  },
            });
            if (!result.ok) throw '';
        } catch {
            alert('Entering raffle failed. Sign out and in again then try again and if error persists, please report in our discord server');
        } finally {
            setRaffleSpinner(false);
        }
        if(result.ok){ 
            try {
                window.analytics.logEvent('purchase',  { value: raffle.price, items: [{name: raffle.name }, ...entry.extras.filter(x => x.items > 0)] });
            } catch {}
            getRaffles();
        }
    };

    return (<div>
        <h4>Enter the raffle for</h4>
        <h2>{raffle.name}</h2>
        <div>
            <h4>Price: </h4>
            <h3>${raffle.price}</h3>
        </div>
        <div className="description" >
            <div dangerouslySetInnerHTML={{ __html: raffle.description }} />
        </div>
        {
            raffle.options.length > 1 && <div>
                <span className={showWarning?"warning":""}><h4>Select color option:</h4></span>
                {raffle.options.map((o, i:number) => {
                    return (<div key={i} className='raffle-options'>
                            <input id={`option${i}`} type='radio' name="options" value={o.name}
                                onChange={(e) => {
                                    setShowWarning(false);
                                    const options = entry.options;
                                    options.name = e.target.value;
                                    setEntry({...entry, options: options})
                                }}
                                ></input>
                                <label htmlFor={`option${i}`}>{o.name} </label>
                        </div>);
                })}
            </div>
        }
        <div className="images">
            {slides.map((s, i:number) => {
                return (<img key={s.key} className={visibleImage == i ? "keyboard-image active": "keyboard-image"} src={s.src} alt={s.alt} />)
            })}
        </div>
        <div className='extras'>
            <h4>Extras: </h4>
            {raffle.extras.map((o,i:number) => {
                return (<div key={i}>
                    {o.name} - {o.price} <input type='number' 
                            min="0" max="10" inputMode="numeric"
                            defaultValue={entry.extras[i].items}
                            onChange={(e) => {
                                const extras = entry.extras;
                                extras[i].items = e.target.value;
                                setEntry({...entry, extras: extras})
                            }}
                        ></input>
                </div>);
            })}
        </div>
        <p>
            If you win the raffle, we will send an invoice to <b>{loginInfo.email}</b>.<br/>
        </p>
        {modalOpen && <div className="modal">
            <div>
                <h2>Enter the raffle for {raffle.name}?</h2>
                <p>Once your entry has been entered it can't be changed. Make sure your selection is correct.</p>
                <div>
                    <h4>Your selection:</h4><br/>
                    {entry.options?.name}<br/><br/>
                    <h4>Extras:</h4><br/>
                    {entry.extras.filter(x => x.items > 0).map(x => (
                        <>{ x.items } x { x.name }<br/></>
                    ))}
                    {!entry.extras.some(x => x.items > 0) && <>No extras selected</>}
                </div>
                {raffleSpinner && <div className="spinner"></div>}
                {!raffleSpinner && <div>
                    <button onClick={enterRaffle} className="active">Enter</button>
                    <button onClick={()=>{setModalOpen(false)}} className="passive">Cancel</button>
                </div>}
            </div>
        </div>}
        {showWarning && <div className={showWarning ? "warning" : ""}>Please select color option </div>}
        <button onClick={showModal}>Enter raffle</button>
    </div>);
};