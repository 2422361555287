import React from "react";

export default ({checkMember}) => {
    return (<p>
        To be eligible for Eniigma keyboard raffles you have to be a member of the Eniigma Discord.<br/>
        Go to <a href="https://eniigmakeyboards.com/discord">eniigmakeyboards.com/discord</a> and join our community. <br/>
        If you just joined, check again:
        <br/>
        <button onClick={checkMember}>Check membership</button>
    </p>);
};