import React, { useState, useEffect } from "react";
import logo from "url:./assets/logo_small.png";
import { getQueryVariable } from './helpers';

import Login from "./Login";
import Logout from "./Logout";
import NotMember from "./NotMember";
import NoRaffles from "./NoRaffles";
import Raffle from "./Raffle";
import RaffleEntered from "./RaffleEntered";

import "./index.css";

export default () => {
    const [loginInfo, setLoginInfo] = useState({
        loggedIn: false,
        username: "",
        discriminator: "",
        token: "",
        eniigma_user: false,
        id: "",
        avatar: "",
        email: "",
        verified: false,
        mfa: false
    });

    interface Raffle {
        ongoing: boolean,
        entered: boolean
    }
    const [raffle, setRaffle] = useState<Raffle>(null);
    const [loginSpinner, setLoginSpinner] = useState(false);
    const [raffleSpinner, setRaffleSpinner] = useState(false);
    const [code,] = useState(getQueryVariable("code"));

    useEffect(()=> {
        const tryLogin = async () => {
            setLoginSpinner(true);
            try {
                const result = await fetch("/api/login", {
                    method: "POST"
                });
                const json = await result.json();
                if(json.username) {
                    setLoginInfo({...json, loggedIn: true});
                }
            } catch {
                alert('Signing in failed. Try again and if error persists, please report in our discord server');
            }
            finally {
                setLoginSpinner(false);
            }
        };
        if(!loginInfo.loggedIn && !code){
            tryLogin();
        }
    }, []);

    const checkMember = async () => {
        if(!loginInfo.loggedIn) return;
        setRaffleSpinner(true);
        try {
            const result = await fetch("/api/checkMember", {
                method: "POST"
            });
            const json = await result.json();
            setLoginInfo({...json, loggedIn: true});
        } catch {
            alert('Checking if discord member failed. Sign out and in again then try again and if error persists, please report in our discord server');
        }
        finally {
            setRaffleSpinner(false);
        }
    };

    const getRaffles = async () => {
        if(!loginInfo.loggedIn) return;
        setRaffleSpinner(true);
        try {
            const result = await fetch("/api/raffle", {
                method: "POST"
            });
            const json = await result.json();
            setRaffle(json);
        } catch {
            alert('Getting raffles failed. Sign out and in again then try again and if error persists, please report in our discord server');
        }
        finally {
            setRaffleSpinner(false);
        }
    };

    useEffect(()=> {
        getRaffles();
    }, [loginInfo]);

    useEffect(()=> {
        const loginWithCode = async (code: string) => {
            setLoginSpinner(true);
            try {
                const result = await fetch("/api/loginWithCode", {
                    body: JSON.stringify({
                        code: code,
                        redirectUri: process.env.DISCORD_RETURN_URL
                    }),
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                      },
                });
                const json = await result.json();
                setLoginInfo({...json, loggedIn: result.ok});
                history.replaceState({}, "" , "/");
            } catch {
                alert('Signing in failed. Try again and if error persists, please report in our discord server');
            }
            finally {
                setLoginSpinner(false);
            }
        };
        if(!loginInfo.loggedIn && code) {
            loginWithCode(code);
        }
    }, [code]);

    if(!loginInfo.loggedIn){
        return (<div>
            <img className="logo" height="100" width="100" src={logo} alt="Eniigma logo"></img>
            <h1>Eniigma Keyboards Raffles</h1>
            {loginSpinner && <div className="spinner"></div>}
            {!loginSpinner && <Login />}
            Please read our <a href="https://eniigmakeyboards.com/policies/privacy-policy">Privacy Policy</a>.
        </div>);
    }
    return (<div>
        <img className="logo" height="100" width="100" src={logo} alt="Eniigma logo"></img>
        <h1>Eniigma Keyboards Raffles</h1>
        {loginSpinner && <div className="spinner"></div>}
        {!loginSpinner &&
        <div className="loginInfo">
            {
                loginInfo.avatar && 
                <><img className="avatar" height="50" width="50" alt="User discord avatar" src={`//cdn.discordapp.com/avatars/${loginInfo.id}/${loginInfo.avatar}?size=256`} /><br/></>
            }
            <div className="user">Hello, {loginInfo.username}</div>
            <Logout setLoginInfo={setLoginInfo} setSpinner={setLoginSpinner} />
        </div>}
        {raffleSpinner && <div className="spinner"></div>}
        {(!raffleSpinner && !loginInfo.eniigma_user) && <NotMember checkMember={checkMember} />}
        {(!raffleSpinner && loginInfo.eniigma_user && raffle && !raffle.ongoing) && <NoRaffles recheck={getRaffles} />}
        {(!raffleSpinner && loginInfo.eniigma_user && raffle && raffle.ongoing && !raffle.entered) && <Raffle raffle={raffle} getRaffles={getRaffles} loginInfo={loginInfo} />}
        {(!raffleSpinner && loginInfo.eniigma_user && raffle && raffle.ongoing && raffle.entered) && <RaffleEntered raffle={raffle} />}
        <a href="https://eniigmakeyboards.com/policies/privacy-policy" target="_blank" rel="noopener">Privacy Policy</a>
    </div>);
};